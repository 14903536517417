<template>
  <div>
    <h4>{{ $t('ebilling.get_future_bills') }}</h4>
    <!-- Manage Subscription -->
    <template v-if="subscription">
      <div v-dompurify-html="$t('ebilling.signup.email_confirmation', { email_address: subscription?.email })" />
      <div class="mt-2">
        <b-link
          data-test="ebilling-hide"
          @click.prevent="$emit('change-e-billing-mode', 'closed')"
        >
          {{ $t('ebilling.signup.hide') }}
        </b-link>
      </div>
    </template>
    <!-- Create subscription -->
    <template v-else>
      <div>{{ $t('ebilling.signup.subtitle.anon') }}</div>
      <!-- Name input -->
      <b-form-group
        id="name-input-label"
        label-cols="2"
        content-cols-xl="5"
        content-cols-lg="7"
        content-cols-sm="10"
        :label="$t('delivery.name.default') + ' *'"
        label-for="ebilling-name-input"
      >
        <b-form-input
          :ref="`ebilling-name-input-${payableSavePath}`"
          :key="`ebilling-name-input-${payableSavePath}`"
          v-model="name"
          class="bg-white"
          :state="!v$.name.$error"
          aria-labelledby="name-input-label"
          name="ebilling-name-input"
          data-test="ebilling-name-input"
          @blur="v$.name.$touch()"
          @keydown.enter="submit"
        />
        <ValidationErrors
          :validator="v$.name"
          :errors="{
            required: $t('delivery.name.required'),
          }"
        />
      </b-form-group>
      <!-- Email input -->
      <b-form-group
        id="email-input-label"
        label-cols="2"
        content-cols-xl="5"
        content-cols-lg="7"
        content-cols-sm="10"
        :label="$t('email.label') + ' *'"
        label-for="ebilling-email-input"
        :description="$t('required.label')"
      >
        <b-form-input
          :key="`ebilling-email-input-${payableSavePath}`"
          v-model="email"
          class="bg-white"
          type="email"
          name="ebilling-email-input"
          aria-labelledby="email-input-label"
          data-test="ebilling-email-input"
          :state="!v$.email.$error"
          @blur="v$.email.$touch()"
          @keydown.enter="submit"
        />
        <ValidationErrors
          :validator="v$.email"
          :errors="{
            required: $t('email.required'),
            email: $t('email.invalid')
          }"
        />
      </b-form-group>
      <!-- Conditional Terms of Use -->
      <span v-if="showTermsOfUse">
        <p>{{ $t('ebilling.terms_of_use.anon_preface') }}
          <a
            :href="termsOfUseLink"
            target="_blank"
          >
            {{ $t('ebilling.terms_of_use.link') }}
          </a>.</p>
      </span>
      <!-- Buttons -->
      <b-form-group
        label-cols="2"
        content-cols-xl="5"
        content-cols-lg="7"
        content-cols-sm="10"
      >
        <b-row
          align-h="end"
          no-gutters
        >
          <b-button
            variant="outline-primary"
            @click.prevent="$emit('change-e-billing-mode', 'closed')"
          >{{
            $t('common.cancel')
          }}</b-button>
          <ProgressButton
            ref="start"
            :waiting="$wait.is(`e-billing subscription ${payableSavePath}`)"
            data-test="e-billing-subscribe"
            variant="primary"
            class="ml-2"
            :disabled="!isFormValid"
            @click="$emit('subscribe', { name, email, paperless: false })"
          >
            {{ $t('ebilling.signup.anon.button') }}
          </ProgressButton>
        </b-row>
      </b-form-group>
    </template>
  </div>
</template>

<script>
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'

export default {
  emits: ['subscribe', 'change-e-billing-mode'],
  setup () {
    return {
      v$: useVuelidate(),
    }
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  components: {
    ProgressButton,
    ValidationErrors,
  },
  props: {
    payableSavePath: {
      type: String,
      required: true,
    },
    subscription: {
      type: Object,
      required: false,
      default: undefined,
    },
    eBillingMode: {
      type: String,
      required: true,
    },
    showTermsOfUse: {
      type: Boolean,
      required: true,
    },
    termsOfUseLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    isFormValid () {
      return !this.v$.$invalid
    },
  },
  methods: {
    submit () {
      if (!this.isFormValid) {
        // Show all validation errors and exit
        this.v$.$touch()
        return
      }
      // Hardcoded to false as anon subs can't be paperless.
      this.$emit('subscribe', { name: this.name, email: this.email, paperless: false })
    },
  },
  data: () => ({
    name: '',
    email: '',
    paperless: false,
  }),
}
</script>
