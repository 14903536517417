<template>
  <div
    role="search"
    class="search-box position-relative"
  >
    <input
      ref="formInput"
      :value="value"
      :placeholder="translatedPlaceholder"
      :class="{
        'pr-6': showIcon && addPadding,
        'pr-0': !addPadding,
        'responsive-placeholder': responsivePlaceholder,
        'search-with-icon': showIcon,
        'is-valid': !v$.value.$error,
        'is-invalid': v$.value.$error,
      }"
      :aria-label="translatedPlaceholder"
      :disabled="disabledInput"
      type="search"
      :name="name"
      class="form-control"
      @input="(event) => { v$.$reset(); $emit('input', event.target.value) }"
      @keyup.enter="$emit('search')"
    >
    <ValidationErrors
      :validator="v$.value"
      :errors="$t('value_required')"
    />

    <SearchButton
      v-if="showIcon"
      class="search-button"
      :spinning="$wait.is(spinnerEventName)"
      @click="search"
    />
  </div>
</template>

<script>
import SearchButton from './SearchButton.vue'
import ValidationErrors from './ValidationErrors.vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

export default {
  emits: ['input', 'search'],
  components: {
    SearchButton,
    ValidationErrors,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      // q1 is a terrible name but matches every PEx site so it'll keep
      // autocomplete working
      default: 'q1',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    addPadding: {
      type: Boolean,
      default: true,
    },

    // Shrink the input placeholder font size on small screens? This is helpful
    // if the placeholder text is long.
    responsivePlaceholder: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
    spinnerEventName: {
      type: String,
      default: 'searching',
    },
  },

  computed: {
    translatedPlaceholder () {
      if (typeof this.placeholder === 'string') {
        return this.placeholder
      }
      else {
        return this.placeholder[this.$i18n.locale] || ''
      }
    },
  },

  methods: {
    search () {
      if (!this.validate()) {
        return
      }
      this.$emit('search')
    },

    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    getSelectionStart () {
      return this.$refs.formInput.selectionStart
    },

    getSelectionEnd () {
      return this.$refs.formInput.selectionEnd
    },

    setSelectionRange (start, end) {
      this.$refs.formInput.setSelectionRange(start, end)
    },
  },

  validations: {
    value: {
      required: requiredIf(function () {
        return this.required
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  border-radius: $input-border-radius !important;
  box-sizing: border-box;

  @include media-breakpoint-down(xs) {
    font-size: 1rem !important;

    &.responsive-placeholder::placeholder {
      font-size: 1rem !important;
    }
  }
  @include media-breakpoint-375-down {
    &.responsive-placeholder::placeholder {
      font-size: .8rem !important;
    }
  }
}

.search-button {
  bottom: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
