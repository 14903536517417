<!-- Self Serve is a page for allowing users to select items from a list or
     fill out item details themselves. It's meant to be an alternative to
     Item List and does not support searching payables. -->
<template>
  <div
    data-test="self-serve-box"
    class="bg-white rounded-xl p-0 overflow-hidden d-flex no-gutters"
  >

    <div class="b-col col-12">
      <!-- "successfully added" alert -->
      <Alert
        v-if="successItems"
        variant="success"
        data-test="add-success"
        :show-icon="true"
        :dismissible="true"
        class="mb-0 mt-3 mt-sm-4 mt-md-5 mt-xl-6 mx-3 mx-sm-4 mx-md-5 mx-xl-6"
      >
        <!-- TODO: Use i18n's plural functionality -->
        {{
          successItems > 1 ?
            $t('self_serve.success_plural', { num: successItems }) :
            $t('self_serve.success_singular', { num: successItems })
        }}
      </Alert>

      <SelfServeItem
        v-for="(item, index) in items"
        :key="item.uid"
        ref="selfServeItems"
        data-test="self-serve-item"
        class="p-3 p-sm-4 p-md-5 p-xl-6"
        :class="{'border-top': index}"
        :payable-list="availableDropdownPayables(item.payable)"
        :value="item"
        :item-name="itemName"
        :min-amount="item.minAmount"
        :max-amount="item.maxAmount"
        :hide-remove="items.length === 1"
        :search-type="searchType"
        :blind-display-name-label="blindDisplayNameLabel"
        :blind-display-name-placeholder="blindDisplayNamePlaceholder"
        :generate-unique-id="generateUniqueId"
        @remove="removeItem(index)"
        @change="itemChanged(index, $event)"
      >
        <!-- Pass slots through to child component -->
        <template #cornerIndicator="props">
          <slot
            name="cornerIndicator"
            v-bind="props"
          />
        </template>

        <template #freeformField="props">
          <slot
            name="freeformField"
            v-bind="props"
          />
        </template>

      </SelfServeItem>

      <div class="border-top d-flex b-row flex-wrap no-gutters px-3 px-sm-4 px-md-5 px-xl-6 p-2 p-sm-3 p-md-4 p-xl-5">
        <!-- "add another" link -->
        <div class="b-col d-flex text-nowrap my-2 mr-1">
          <b-link
            class="d-flex flex-row align-items-center align-self-center"
            data-test="add-list-item"
            @click="addItem(); hideSuccess()"
          >
            <svgicon
              icon="plus-circle"
              width="1rem"
              height="1rem"
              class="svg-primary mr-2 svg-thick"
            />
            {{ $t("self_serve.add_another", { item: itemName[$i18n.locale] }) }}
          </b-link>
        </div>

        <!-- Buttons -->
        <div class="b-col d-flex flex-row flex-grow-1 justify-content-end">
          <b-button
            class="my-2 font-weight-semibold"
            variant="outline-primary"
            data-test="clear-all"
            @click="clearAll"
          >
            {{ $t("self_serve.clear_all") }}
          </b-button>

          <slot
            name="addButton"
            :payables="payables"
            :validate="validate"
            :fetch-payables="fetchPayables"
            :on-success="showSuccess"
            :clear="clearAll"
          />

        </div>
      </div>

      <div
        v-if="showExampleImageText || exampleImage"
        class="px-3 px-sm-4 px-md-5 px-xl-6 p-2 p-sm-3 p-md-4 p-xl-5"
      >
        <p
          v-if="showExampleImageText"
          v-dompurify-html="exampleImageText[$i18n.locale] || ''"
          data-test="search-example-text"
        />
        <b-img
          v-if="exampleImage"
          class="w-100"
          :src="exampleImage"
          :alt="exampleImageAltText[$i18n.locale] || ''"
          fluid
          data-test="search-example-image"
        />
      </div>

    </div>
  </div>
</template>

<script>
import SelfServeItem from './SelfServeItem.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import { sentryException } from '../../sentry.ts'
import { getMessageMap } from '@grantstreet/psc-vue/utils/i18n.ts'

import { v4 as uuid } from 'uuid'
import store from '../../store/index.ts'
import { freeformFieldDefinitions } from '../../index.ts'

export default {
  components: {
    SelfServeItem,
    Alert,
  },

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },
    itemName: {
      type: Object,
      default: () => getMessageMap('item.default'),
    },
    searchType: {
      type: String,
      required: true,
      validator: type => type === 'blind' || type === 'item-dropdown',
    },
    exampleImage: {
      type: String,
      default: '',
    },
    exampleImageText: {
      type: Object,
      default: () => ({}),
    },
    exampleImageAltText: {
      type: Object,
      default: () => ({}),
    },
    blindDisplayNameLabel: {
      type: Object,
      required: true,
    },
    blindDisplayNamePlaceholder: {
      type: Object,
      required: true,
    },
    generateUniqueId: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      // See .addItem.
      // Reactivity is intentionally triggered by reassignment.
      // 1. It avoids a deep watcher.
      // 2. It's more explicit and avoids nasty mutation bugs/features.
      items: [],

      // It seems like `items` is used by this component for the dropdown menu
      // but gets modified by blind and unique item dropdown sites whenever
      // a user adds an item to their cart. This results in the `SelfServeItem`
      // component momentarily taking on the appearance of the add payable(s)
      // before redirecting to the checkout screen. This might look like custom
      // fields or a whole list of empty dropdown selectors displaying on the
      // screen before redirecting.
      //
      // Instead, we can use a `payables` data prop to be read from instead of
      // `items` so that when `addToCart` is called it won't disrupt the
      // existing `items`, which seems to solve the momentary display of the
      // newly added payables.
      //
      // Since `items` is leaned on heavily for the "self-serve" functionality
      // of sites that rely on this component, it is largely untouched.
      payables: [],

      // Payables that populate the dropdowns on'item-dropdown' sites.
      dropdownPayables: [],

      // Used for showing success message after adding items to cart
      successItems: 0,

      isUniqueItemDropdownSearch: false,

      // This is set by the base UniqueItemSelect adaptor class in the Payables
      // API
      maxUniqueQuantity: 500,
    }
  },

  computed: {
    // Items with real payables mapped by path
    // Doesn't include items without a proper payable
    itemsByPath () {
      return this.items.reduce((map, item) => {
        if (item.payable.path) {
          map[item.payable.path] = item
        }
        return map
      }, {})
    },

    showExampleImageText () {
      return Object.keys(this.exampleImageText || {}).length
    },
  },

  async mounted () {
    // Add blank SelfServeItem to list
    this.addItem()

    if (this.searchType === 'item-dropdown') {
      this.isUniqueItemDropdownSearch = this.generateUniqueId

      // Look up payables from our adaptor to populate item dropdowns
      this.dropdownPayables = (await store.dispatch('searchPayables', {
        payablesAdaptor: this.payablesAdaptor,
        data: {},
        language: this.$i18n.locale,
      })).payables
    }
  },

  activated () {
    this.hideSuccess()
  },

  methods: {
    availableDropdownPayables (currentPayable) {
      return this.dropdownPayables.filter(payable => {
        // Include the currentPayable (otherwise it won't select once clicked)
        if (payable === currentPayable) {
          return true
        }

        // Include all items that aren't selected
        if (!this.itemsByPath[payable.path]) {
          return true
        }

        // Even if the quantity isn't modifiable,
        // we let you add a separate item
        // (this facilitates blind payment sites)
        if (!payable.isQuantityModifiable) {
          return true
        }

        // If there are freeform fields
        // we let you add as a separate item
        // over and over
        // (so quantity, as passed to cart, isn't really modifiable,
        // but the number of times the item is in your cart is)
        return freeformFieldDefinitions(payable).length > 0
      })
    },

    showSuccess (number) {
      this.successItems = number
    },

    hideSuccess () {
      this.successItems = 0
    },

    clearAll () {
      // Reassignment triggers reactive updates
      this.items = []
      this.addItem()
      this.payables = []
    },

    // Adds a blank item to the list
    addItem () {
      // Reassignment triggers reactive updates
      this.items = [
        ...this.items,

        {
          // Used for :key (payables aren't unique)
          uid: uuid(),
          quantity: 1,
          amount: 0,
          // Payable will be an empty, dummy object.
          // For dropdown sites it'll be set to a real payable on selection.
          // For blind sites that payable will be created by the adapter when
          // the addButton is clicked.
          payable: {},
          // Blind sites use user input to create a payable
          displayName: '',
          userParameters: {},
          payableError: '',
        },
      ]
    },

    removeItem (index) {
      this.items.splice(index, 1)
      // Reassignment triggers reactive updates
      this.items = [...this.items]
      this.payables = [...this.items]
    },

    itemChanged (index, item) {
      this.items[index] = item
      // Reassignment triggers reactive updates
      this.items = [...this.items]
      this.payables = [...this.items]
      this.hideSuccess()
    },

    // Validates all SelfServeItems
    validate () {
      // .reduce, not .some or .every because we want to validate all fields
      return this.$refs.selfServeItems.reduce((valid, item) => item.validate() && valid, true)
    },

    async fetchPayables () {
      // Validate before calling

      // For both blind-type and unique item dropdown-type sites, we need to
      // perform call the Payables API to create more customized payables before
      // they can be added to the cart. Other types of sites don't need to do
      // this, so we just early exit instead.
      if (this.searchType !== 'blind' && !this.isUniqueItemDropdownSearch) {
        return true
      }

      // Blind sites create payables by searching them. All searches must be
      // successful.
      //
      // Item dropdown sites that use the "Generate Unique ID" site setting also
      // follow this workflow.
      this.payables = []

      return (
        await Promise.all(this.items.map(async item => {
          try {
            // Set up the query params based on the search type and additional
            // site settings

            // eslint-disable-next-line camelcase
            const query = { display_name: item.displayName || item.payable.displayName }

            // Unique dropdown items
            if (this.isUniqueItemDropdownSearch) {
              // This is also enforced by the UniqueItemSelect adaptor via the
              // Payables API, but this will give the user a nicer message
              if (item.quantity > this.maxUniqueQuantity) {
                throw new Error('INVALID_UNIQUE_ITEM_QUANTITY')
              }

              query.quantity = item.quantity
            }
            // Blind search
            else {
              query.amount = item.amount
            }

            // Look up (create) payables from our adaptor
            const payables = (await store.dispatch('searchPayables', {
              payablesAdaptor: this.payablesAdaptor,
              data: { query },
              language: this.$i18n.locale,
            })).payables

            if (payables.length < 1) {
              const searchType = this.searchType === 'blind'
                ? 'Blind'
                : 'Unique Item Dropdown'
              const message = `Search to ${searchType} adaptor ${this.payablesAdaptor} returned no payables`
              console.error(message)
              sentryException(new Error(message))
              throw new Error('INVALID_SEARCH_PAYABLES_RESPONSE')
            }

            // Catching incorrect adaptors from searchPayables for unique items
            if (this.isUniqueItemDropdownSearch) {
              payables.forEach(payable => {
                if (!payable.displayName.includes(query.display_name)) {
                  const message = `Search to adaptor with display name ${query.display_name} returned adaptor with display name ${payable.displayName}`
                  console.error(message)
                  sentryException(new Error(message))
                  throw new Error('INVALID_SEARCH_PAYABLES_RESPONSE')
                }
              })
            }

            const payableItems = []
            let minAmount = 0.01
            let maxAmount = Number.MAX_SAFE_INTEGER

            for (const payable of payables) {
              payableItems.push({
                uid: uuid(),
                quantity: payable.quantity,
                amount: payable.amount,
                payable,
                displayName: payable.displayName,
                userParameters: {},
                payableError: '',
              })

              minAmount = Math.max(minAmount, payable.minAmount || 0)
              maxAmount = Math.min(maxAmount, payable.maxAmount || Number.MAX_SAFE_INTEGER)
            }

            item.minAmount = minAmount
            if (maxAmount < Number.MAX_SAFE_INTEGER) {
              item.maxAmount = maxAmount
            }

            // Set the cart's items using the response
            this.payables = this.payables.concat(payableItems)

            // Reset the payable error message
            item.payableError = ''
            return true
          }
          // The payable search failed validation in some way, so let the user
          // know
          catch (error) {
            let message
            if (error?.response?.data?.errorMessage) {
              message = error.response.data.errorMessage
            }
            else if (error?.message === 'INVALID_UNIQUE_ITEM_QUANTITY') {
              const args = { quantity: this.maxUniqueQuantity }
              message = this.$t('search.unique.quantity_error', args)
            }
            else {
              message = this.$t('search.blind.validation_error')
            }

            item.payableError = message

            return false
          }
        }))
        // Every promise must return true
      ).every(result => result)
    },

  },
}
</script>
