<!-- SelfServeItem represents a row in an SelfServe page, which displays a
dropdown or an input field to select an item and amount/quantity inputs as
necessary. It is currently assumed that all items are quantity modifiable -->
<template>
  <div>
    <div class="d-flex flex-row no-gutters">
      <div class="text-light-gray font-weight-semibold col-7 col-sm-8 col-lg-7 col-xl-9">
        <div v-if="searchType === 'item-dropdown'">
          {{ firstToUpperCase(itemName[$i18n.locale]) }}
          <b-form-select
            :value="value.payable"
            :aria-label="$t('item_dropdown.select_label')"
            data-test="item-select"
            class="mt-1"
            :options="dropdownOptions"
            :state="!v$.value.payable.$error"
            :select-size="1"
            value-field="payable"
            text-field="name"
            @change="itemChanged('payable', $event)"
            @input="v$.value.payable.$reset()"
            @blur="v$.value.payable.$touch()"
          />
          <ValidationErrors
            class="top-auto"
            :validator="v$.value.payable"
            :errors="{
              required: $t('self_serve.required'),
            }"
          />
        </div>
        <div v-else-if="searchType === 'blind'">
          {{ blindDisplayNameLabel[$i18n.locale] }}
          <b-form-input
            :value="value.displayName"
            :aria-label="blindDisplayNameLabel[$i18n.locale]"
            data-test="blind-input"
            class="mt-1"
            :placeholder="blindDisplayNamePlaceholder[$i18n.locale]"
            :state="!v$.value.displayName.$error"
            @change="itemChanged('displayName', $event)"
            @input="v$.value.displayName.$reset()"
            @blur="v$.value.displayName.$touch()"
          />
          <ValidationErrors
            class="top-auto"
            :validator="v$.value.displayName"
            :errors="{
              required: $t('self_serve.required'),
            }"
          />
        </div>
      </div>

      <div
        v-if="hasPayable && value.payable.isQuantityModifiable && freeformFields.length === 0"
        class="item-inputs text-light-gray font-weight-semibold ml-2 ml-lg-4"
      >
        <div>{{ $t("quantity.shorthand") }}</div>
        <!-- TODO: Pretty sure parseInt is unnecessary -->
        <b-form-input
          :value="value.quantity"
          :aria-label="$t('quantity.longhand')"
          :state="!v$.value.quantity.$error"
          data-test="quantity-input"
          type="number"
          min="1"
          step="1"
          class="quantity form-control d-inline text-center mt-1"
          @change="itemChanged('quantity', Number.parseInt($event))"
          @input="v$.value.quantity.$reset()"
          @blur="v$.value.quantity.$touch()"
        />
        <ValidationErrors
          class="top-auto"
          :validator="v$.value.quantity"
          :errors="{
            minValue: $t('amount.error.min_value', { min: 1 }),
          }"
        />
      </div>
      <div
        v-if="searchType === 'blind' || value.payable.isAmountModifiable"
        class="item-inputs text-light-gray font-weight-semibold ml-2 ml-lg-4"
      >
        <div>{{ $t("self_serve.amount") }}</div>
        <!-- TODO: Pretty sure parseFloat is unnecessary. If it is, use psc-js
        parseNumber instead -->
        <b-form-input
          :value="decimalFormat(value.amount)"
          :aria-label="$t('self_serve.amount')"
          data-test="self-serve-amount"
          :state="!v$.value.amount.$error"
          type="number"
          :min="minAmount"
          :max="maxAmount"
          step="0.01"
          class="quantity form-control d-inline text-center mt-1"
          @change="itemChanged('amount', Number.parseFloat($event))"
          @input="v$.value.amount.$reset()"
          @blur="v$.value.amount.$touch()"
          @focus="selectInput($event)"
        />
        <ValidationErrors
          class="top-auto"
          :validator="v$.value.amount"
          :errors="{
            required: $t('amount.error.required'),
            minValue: $t('amount.error.min_value', { min: decimalFormat(minAmount) }),
            maxValue: $t('amount.error.max_value', { max: decimalFormat(maxAmount) }),
          }"
        />
      </div>
      <div
        v-if="!hideRemove"
        class="d-flex pt-3 align-items-center ml-2 ml-lg-4"
      >
        <b-link
          data-test="remove-item"
          @click="$emit('remove')"
        >
          {{ $t('self_serve.remove') }}
        </b-link>
      </div>

      <slot
        name="cornerIndicator"
        :payable="value.payable"
      />

    </div>
    <div
      v-if="hasPayable"
      class="col-12"
    >

      <slot
        v-for="field of freeformFields"
        ref="freeformFields"
        name="freeformField"
        :field="field"
        :update-field="field => itemChanged('userParameters', field)"
      />

    </div>
    <Alert
      v-if="showPayableError"
      variant="warning"
      :show-icon="false"
      :dismissible="false"
    >
      {{ value.payableError }}
    </Alert>
  </div>
</template>

<script>
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'
import { decimalFormat } from '@grantstreet/psc-js/utils/numbers.js'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf, required, minValue, maxValue } from '@vuelidate/validators'
import cloneDeep from 'lodash/cloneDeep.js'
import { mapFreeformFields } from '../../index.ts'

export default {
  emits: ['remove', 'change'],
  components: {
    ValidationErrors,
    Alert,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    // The item
    value: {
      type: Object,
      validator: ({
        quantity,
        amount,
        displayName,
        payable,
        userParameters,
      } = {}) => (
        typeof quantity === 'number' &&
        typeof amount === 'number' &&
        typeof displayName === 'string' &&
        payable &&
        userParameters
      ),
      required: true,
    },
    itemName: {
      type: Object,
      required: true,
    },
    // This will almost certainly be at the default until after blind payables
    // are constructed
    minAmount: {
      type: Number,
      default: 0.01,
      validator: min => min >= 0.01,
    },
    // This will almost certainly be at the default until after blind payables
    // are constructed
    maxAmount: {
      type: [Number, undefined],
      default: undefined,
      validator: max => (!max && typeof max !== 'number') || max >= 0.01,
    },
    searchType: {
      type: String,
      required: true,
      validator: type => type === 'blind' || type === 'item-dropdown',
    },
    payableList: {
      type: Array,
      default (props) {
        if (props.searchType === 'item-dropdown') {
          console.error('Invalid prop: custom validator check failed for prop "payableList"')
        }
        return []
      },
    },
    hideRemove: {
      type: Boolean,
      default: false,
    },
    showLogin: {
      type: Function,
      default: () => () => {},
    },
    blindDisplayNameLabel: {
      type: Object,
      required: true,
    },
    blindDisplayNamePlaceholder: {
      type: Object,
      required: true,
    },
    generateUniqueId: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      isUniqueItemDropdownSearch: false,
    }
  },

  computed: {
    // Populate the dropdown options with all of the payables we've been given,
    // formatted as "Payable Name - Cost",
    // along with a default "Select your item" option
    dropdownOptions () {
      const options = this.payableList.map(payable => {
        const amount = payable.amount ? `- ${decimalFormat(payable.amount)}` : ''
        // If the amount is modifiable, don't show the cost in the dropdown
        const name = payable.isAmountModifiable ? payable.displayName : `${payable.displayName} ${amount}`
        return {
          name,
          payable,
        }
      })

      options.unshift({
        name: `— ${this.$t('item_dropdown.select', { item: this.itemName[this.$i18n.locale] })} —`,
        payable: {},
      })
      return options
    },

    freeformFields () {
      const fieldMap = (template) => {
        // Make sure not to modify the template
        const field = cloneDeep(template)
        // Set default value
        field.value = field.value || ''
        // Id field with item's uid
        field.uid = this.value.uid
        return field
      }

      return mapFreeformFields({ payable: this.value.payable, fieldMap })
    },

    hasPayable () {
      return this.value.payable.raw
    },

    showPayableError () {
      return (this.searchType === 'blind' || this.isUniqueItemDropdownSearch) &&
        this.value.payableError
    },
  },

  mounted () {
    if (this.searchType === 'item-dropdown') {
      this.isUniqueItemDropdownSearch = this.generateUniqueId
    }
  },

  validations: {
    value: {
      payable: {
        required: requiredIf(function () {
          return this.searchType === 'item-dropdown'
        }),
      },
      displayName: {
        required: requiredIf(function () {
          return this.searchType === 'blind'
        }),
      },
      quantity: {
        minValue: minValue(1),
      },
      amount: {
        required,
        minValue: function (val) {
          if (this.searchType !== 'blind' && !this.value.payable.isAmountModifiable) {
            return true
          }
          return minValue(this.minAmount).$validator(val)
        },
        maxValue: function (val) {
          if ((this.searchType !== 'blind' && !this.value.payable.isAmountModifiable) || typeof this.maxAmount !== 'number') {
            return true
          }
          return maxValue(this.maxAmount).$validator(val)
        },
      },
    },
  },

  methods: {
    decimalFormat,
    firstToUpperCase,

    itemChanged (key, value) {
      const item = {
        ...this.value,
      }
      if (key === 'userParameters') {
        const fieldKey = value.reportingKey || value.pexKey
        value = {
          ...item.userParameters,
          [fieldKey]: value.value,
        }
      }
      else if (key === 'payable' && typeof value.amount === 'number') {
        item.amount = value.amount
      }

      item[key] = value

      this.$emit('change', item)
    },

    selectInput (event) {
      if (decimalFormat(this.value.amount) === decimalFormat(0.00)) {
        event.target.select()
      }
    },

    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },
  },
}
</script>

<style lang="scss" scoped>

.top-auto {
  top: auto;
}

// These negative margins mimic the padding of each SelfServeItem row
.corner-offset {
  right: 0;
  margin-top: -2rem;
  @include media-breakpoint-up(xl) {
    margin-top: -2.5rem;
  }
}

.right-1 {
  right: 1rem;
}

</style>
