<template>
  <div class="row">
    <div class="col-auto col-xxl-7">
      <h4
        class="mb-0"
        :class="classes.displayName"
      >
        {{ payable.displayName }}
      </h4>
      <div
        v-if="mdy && !hideMDY"
        :class="classes.description"
      >
        <span :class="classes.delinquentFee">{{ delinquentFeeText ? $t('rex.item.expired') : $t('rex.item.expires') }}: </span>
        <span class="text-nowrap">
          <span :class="classes.delinquentFee">{{ mdy }}</span>
          <span
            v-if="delinquentFeeText"
            v-b-tooltip.hover.bottomright
            :title="delinquentFeeText"
          >
            <svgicon
              icon="question-mark-circle"
              width="1rem"
              height="1rem"
              class="icon mx-1"
            />
          </span>
        </span>
      </div>
      <div
        v-if="payable.description"
        :class="classes.description"
      >
        {{ payable.description }}
        <!-- Display either the data for the new plate type if this has been
        retyped by payables, or the original plate type if it exists. (Can a
        renewal payable not have a customParameters.plate_type_description?) -->
        <div v-if="payable.rexHubCustomParameters?.plateChange?.newPlateDescription || payable.customParameters.plate_type_description">
          <br>
          <b>{{ $t('rex.plate_change.type') }}</b>{{ payable.rexHubCustomParameters?.plateChange?.newPlateDescription || payable.customParameters.plate_type_description }}
        </div>

        <b-row
          v-if="showInsuranceAffidavit"
          class="px-3 pb-0 d-inline-block text-nowrap"
        >
          <svgicon
            icon="document"
            class="align-self-center"
          />
          <b-button
            v-b-toggle="insuranceSidebarID"
            variant="link"
            class="px-1 text-decoration-none"
            size="sm"
            :disabled="$wait.is('changing-payable')"
          >
            {{ $t('rex.insurance_affidavit.label') }}
          </b-button>
          <svgicon
            :fill="!hasUpdatedInsurance"
            :icon="!hasUpdatedInsurance ? 'exclamation-red-circle' : 'check-circle'"
            class="align-self-center"
          />
        </b-row>

        <b-row
          v-if="showPlateChange && !isMSI && !readOnly"
          class="px-3 d-inline-block text-nowrap col-12"
        >

          <svgicon
            icon="license-plate"
            class="align-self-center"
          />

          <template
            v-if="!hasPlateChange"
          >
            <b-button
              v-b-toggle="plateChangeSidebarID"
              variant="link"
              size="sm"
              class="px-1 text-decoration-none"
              :disabled="$wait.is('changing-payable')"
            >
              {{ $t('rex.plate_change.default') }}
            </b-button>
          </template>
          <template
            v-else
          >
            <b-button
              variant="link"
              size="sm"
              class="px-1 text-decoration-none"
              :disabled="$wait.is('changing-payable')"
              @click="() => {
                // Reset by not passing a plate code
                performPlateChange()
              }"
            >
              {{ $t('rex.plate_change.revert') }}
            </b-button>
            <div v-if="showPlateFees">
              <h5>
                {{ $t('rex.plate_change.fees') }}{{ payable.rexHubCustomParameters.plateChange?.fees[selectedRenewalDuration]?.totalAddl.display }}
              </h5>
            </div>
          </template>
        </b-row>

      </div>
      <div
        v-if="shippingOnly && enableRExHubPickUp"
        :class="classes.description"
      >
        {{ $t('rex.item.shipping_only') }}
      </div>
    </div>
    <div class="col-auto col-xxl-5 d-flex align-items-center justify-content-xxl-end">
      <div
        v-if="readOnly"
        data-test="renewal-duration-detail"
        :class="classes.description"
      >
        {{ $t('rex.duration.months', { months: selectedRenewalDuration }) }}
      </div>
      <div v-else>
        <div
          v-if="renewalDurationOptions.length"
          class="w-100"
        >
          <b-form inline>
            <b-form-select
              id="renewalDurationSelection"
              :value="selectedRenewalDuration"
              data-test="renewal-duration-select"
              class="my-2 mx-0 mw-100"
              size="sm"
              :disabled="$wait.is('changing-payable') || $wait.is('modifying.*') || $wait.is('submitting')"
              :options="renewalDurationOptions"
              @change="setRExRenewalDuration"
            />
          </b-form>
        </div>
      </div>
    </div>

    <div
      v-if="showRentalParkStatus && !readOnly && !isMSI"
      data-test="rental-park-options"
      class="mt-2 col-12"
      :class="rentalParkError ? 'text-danger' : ''"
    >
      <svgicon
        v-if="rentalParkError"
        icon="alert-exclamation-triangle"
        class="mr-2 align-baseline"
        height="2rem"
        width="2rem"
        :fill="false"
      />
      <div class="d-inline-block">
        {{ $t('rex.mobile_home.is_in_rental_park') }}
        <b-form-radio-group
          :id="`is-in-rental-park-${_uid}`"
          v-model="selectedIsInRentalPark"
          :options="[
            {
              text: $t('common.yes'),
              value: 'yes',
            },
            {
              text: $t('common.no'),
              value: 'no',
            },
          ]"
          :name="`is-in-rental-park-${_uid}`"
          :disabled="$wait.is('changing-payable') || $wait.is('modifying.*') || $wait.is('submitting')"
          required
          @change="performRentalParkChange"
        />
      </div>
    </div>
    <RenewalInsuranceAffidavit
      v-if="showInsuranceAffidavit"
      :id="insuranceSidebarID"
      :renewal="payable"
      :read-only="readOnly"
      @inline-insurance-submitted="performInlineInsuranceAffidavit"
    />
    <RenewalPlateChange
      v-if="showPlateChange && !isMSI"
      :id="plateChangeSidebarID"
      :renewal="payable"
      :selected-duration="selectedRenewalDuration"
      @new-plate-saved="performPlateChange"
    />
  </div>
</template>

<script>
import { displayFormat } from '@grantstreet/psc-js/utils/numbers.js'
import { isoToLocalDate, mdy } from '@grantstreet/psc-js/utils/date.js'
import { sentryException } from '../../../../sentry.ts'
import { v4 as uuid } from 'uuid'
import Payable from '../../../../models/Payable.ts'
import store from '../../../../store/index.ts'
import RenewalInsuranceAffidavit from '../../../sidebar/RenewalInsuranceAffidavit.vue'
import RenewalPlateChange from '../../../sidebar/RenewalPlateChange.vue'

export default {
  emits: [
    'change-payable',
    'change-plate',
    'change-rental-park-status',
    'payable-set-child',
    'update-insurance',
  ],
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    // Don't show fees in the description if something else is showing a
    // breakdown already
    showPlateFees: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    RenewalInsuranceAffidavit,
    RenewalPlateChange,
  },

  data () {
    return {
      selectedIsInRentalPark: null,
      rentalParkError: false,

      selectedRenewalDuration: null,

      renewalDurationsToPayables: {},
      renewalDurationOptions: [],

      plateChangeSidebarID: 'plate-change-sidebar-' + this.payable.customParameters?.registrationDetail?.licensePlateNumber,
      insuranceSidebarID: 'insurance-sidebar-' + this.payable.customParameters?.registrationDetail?.licensePlateNumber,

      events: {},

      // These ineligibility codes result in the expiration date being hidden
      hideExpirationCodes: [
        'M006',
        'M012',
      ],
    }
  },

  computed: {

    paymentChoicesPayables () {
      // REx V0 adaptor uses child payables
      if (this.payable?.childPayables?.length) {
        return this.payable.childPayables
      }
      // REx V1 adaptor uses payment choices
      if (this.payable?.paymentChoicesPayables?.length) {
        return this.payable.paymentChoicesPayables
      }
      return undefined
    },

    selectedRenewalDurationPayable () {
      return this.renewalDurationsToPayables[this.selectedRenewalDuration]
    },

    classes () {
      return {
        displayName: `text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
        delinquentFee: this.payable.customParameters.fees?.delinquentFeeAmount ? 'text-danger font-weight-bold' : '',
      }
    },

    delinquentFeeText () {
      const fee = this.payable.customParameters.fees?.delinquentFeeAmount || 0
      return fee ? this.$t('rex.item.late_fee', { amount: displayFormat(fee) }) : ''
    },

    mdy () {
      const expiration = this.payable.customParameters.registrationDetail?.expirationDate
      return expiration ? mdy(isoToLocalDate(expiration)) : ''
    },

    enableRExHubPickUp () {
      return store.state.payablesConfig.enableRExHubPickUp
    },

    shippingOnly () {
      return !this.payable.customParameters.can_be_picked_up
    },

    hideMDY () {
      if (!this.payable.customParameters?.renewability_info?.is_renewable) {
        const ineligibilityReasons = this.payable.customParameters?.ineligibilityReason || {}
        return Object
          .keys(ineligibilityReasons)
          .filter(code => this.hideExpirationCodes.includes(code))
          .length > 0
      }

      return false
    },

    rentalParkStatusFromPayablePath () {
      const queryString = this.payable.path.split('?').pop()
      const query = Object.fromEntries(new URLSearchParams(queryString))
      return query.is_in_rental_park || null
    },

    showRentalParkStatus () {
      // XXX: We should probably create a framework and documentation for
      // custom parameters
      return this.payable.customParameters?.fees?.annual?.rental_park
    },

    isMSI () {
      return this.$route.name === 'my-dashboard'
    },

    isSearchPage () {
      return this.$route.name === 'vehicle-registration'
    },

    isCart () {
      return this.$route.name === 'checkout'
    },

    showPlateChange () {
      return this.payable?.customParameters?.allow_plate_changes && this.payable?.customParameters?.renewal_duration_options?.length
    },

    hasPlateChange () {
      return this.payable?.customParameters?.plate_change !== undefined
    },

    showInsuranceAffidavit () {
      // Only show the insurance affidavit link if what we have is an insurance stop
      return store.state.payablesConfig.useInlineInsuranceAffidavits &&
      (
        this.payable?.isPayableMessage?.code === 'insurance_affidavit_required' ||
        (
          this.payable?.customParameters?.prompt_inline_insurance_affidavit &&
          this.payable?.customParameters?.insurance !== undefined
        )
      )
    },

    hasUpdatedInsurance () {
      return this.showInsuranceAffidavit && this.payable?.customParameters?.insurance !== undefined
    },
  },

  mounted () {
    if (this.readOnly) {
      this.selectedRenewalDuration = this.payable.customParameters.renewalDurationMonths
    }

    this.selectedIsInRentalPark = this.rentalParkStatusFromPayablePath

    // If this is a parent payable, we're on the search page
    // Clear the rental park selection and make the user pick an option
    if (!this.payable.isStandalone && this.isSearchPage) {
      this.selectedIsInRentalPark = null
    }

    // Payables representing the multiple renewal duration options available
    // on the base payable
    const renewalDurationPayables =
      this.paymentChoicesPayables ||
      // Construct manually
      // XXX: When is this supposed to happen?
      this.payable.customParameters?.renewal_duration_options?.map(
        durationOptions => new Payable({
          raw: {
            ...this.payable.raw,
            // Don't re-use the custom_parameters object
            // eslint-disable-next-line camelcase
            custom_parameters: {
              ...this.payable.raw?.custom_parameters,
            },
            ...durationOptions,
          },
        }),
      )

    // Bail out if we have no duration options for this payable.
    // This can occur when payables are ineligible for renewal.
    // ex: mock payable with pin 106502
    if (!renewalDurationPayables) {
      return
    }

    // Build out options and map to reverse them
    for (const payable of renewalDurationPayables) {
      const months = payable.displayName.match(/^(\d+)/)[1]
      this.renewalDurationsToPayables[months] = payable
      this.renewalDurationOptions.push({
        text: this.$t('rex.duration.months', { months }),
        value: months,
      })
    }

    if (this.payable.renewalDurationMonths) {
      this.setRExRenewalDuration(this.payable.renewalDurationMonths)
    }
  },

  methods: {
    setRExRenewalDuration (renewalDuration) {
      this.selectedRenewalDuration = renewalDuration
      let newPayable = this.selectedRenewalDurationPayable

      // Manually set the duration on the payable so that other components can
      // a display fee breakdown
      newPayable.raw.custom_parameters.renewalDurationMonths = renewalDuration

      // payable-set-child seems only to be used by MSI
      // A swap on mount for MSI seems to be required in order to correctly
      // display a selected payment choice ... ¯\_(ツ)_/¯
      // Interestingly, this swap does not result in an actual swap of the underlying payable
      // so when you swap back, you end up in the if block below where the paths are the same.
      // Definitely feels like something a little fishy here. Notably it does not require
      // the new full payable object to "work" like the other swap types below.
      if (this.isMSI) {
        this.$emit('payable-set-child', {
          parent: this.payable,
          child: newPayable,
        })
      }

      // This case prevents us from accidentally recursing
      if (this.payable.path === newPayable.path) {
        return
      }

      // Build up the new payable object
      // We need to build a new payable object for cart and search page
      // swaps to avoid passing an incomplete payable back to ourselves
      newPayable = new Payable({
        raw: {
          ...newPayable.raw,
          // XXX: What are these overrides for specifically?
          // eslint-disable-next-line camelcase
          display_name: this.payable.raw.display_name,
          description: this.payable.raw.description,
          // eslint-disable-next-line camelcase
          custom_parameters: {
            ...this.payable.raw.custom_parameters,
            renewalDurationMonths: renewalDuration,
          },
        },
      })

      // When we are in the cart, use change payable in cart
      if (this.isCart) {
        this.changePayableInCart(newPayable)
        return
      }

      // If we are on the search page, use change-payable
      if (this.isSearchPage) {
        this.$emit('change-payable', newPayable)
      }
    },

    async performRentalParkChange () {
      this.$wait.start('changing-payable')

      const newPayable = await this.fetchUpdatedPayable({
        // eslint-disable-next-line camelcase
        is_in_rental_park: this.selectedIsInRentalPark,
      })

      if (this.isSearchPage) {
        this.rentalParkError = false
        this.$emit('change-rental-park-status', {
          payable: this.payable,
          newPayable,
          selectedRenewalDuration: this.selectedRenewalDuration,
          selectedIsInRentalPark: this.selectedIsInRentalPark,
        })
      }
      else {
        this.changePayableInCart(newPayable)
      }
      this.$wait.end('changing-payable')
    },

    async performPlateChange (newPlateCode) {
      // Re-search current payable with the new plate code value
      this.$wait.start('changing-payable')

      const newPayable = await this.fetchUpdatedPayable({
        // eslint-disable-next-line camelcase
        new_plate_code: newPlateCode,
      })

      if (this.isSearchPage) {
        this.$emit('change-plate', {
          payable: this.payable,
          newPayable,
          selectedRenewalDuration: this.selectedRenewalDuration,
          selectedIsInRentalPark: this.selectedIsInRentalPark,
        })
      }
      else {
        this.changePayableInCart(newPayable)
      }

      this.$wait.end('changing-payable')
    },

    async performInlineInsuranceAffidavit (info) {
      // Re-search current payable
      this.$wait.start('changing-payable')

      const newPayable = await this.fetchUpdatedPayable({
        // eslint-disable-next-line camelcase
        insurance_company_name: info.companyName,
        // eslint-disable-next-line camelcase
        insurance_company_code: info.companyCode,
        // eslint-disable-next-line camelcase
        insurance_policy_number: info.policyNumber,
        // eslint-disable-next-line camelcase
        insurance_vin: info.vin,
        // eslint-disable-next-line camelcase
        insurance_signature_id: info.signatureId,
      })

      if (this.isMSI) {
        store.state.eventBus?.$emit('update-insurance', {
          payable: this.payable,
          newPayable,
          selectedRenewalDuration: this.selectedRenewalDuration,
          selectedIsInRentalPark: this.selectedIsInRentalPark,
        })
      }
      else {
        this.$emit('update-insurance', {
          payable: this.payable,
          newPayable,
          selectedRenewalDuration: this.selectedRenewalDuration,
          selectedIsInRentalPark: this.selectedIsInRentalPark,
        })
      }

      this.changePayableInCart(newPayable)
      this.$wait.end('changing-payable')
    },

    async fetchUpdatedPayable (queryData) {
      // Re-search current payable with updated query data
      const queryString = this.payable.raw.path.split('?').pop()
      const query = Object.fromEntries(new URLSearchParams(queryString))

      const { payables: results } = await store.dispatch('searchPayables', {
        payablesAdaptor: this.payable.adaptor + '/v0',
        data: {
          query: {
            ...query,
            ...queryData,
          },
        },
        language: this.locale || this.$i18n.locale,
      })

      return results?.[0]
    },

    async changePayableInCart (to) {
      store.state.eventBus?.$emit('payable.change-payable', {
        eventId: uuid(),
        from: this.payable,
        to,
        undo: error => this.undoChangePayable(this.payable, error),
      })
    },

    undoChangePayable (from, error) {
      sentryException(error)

      // Reset the dropdown selection
      this.selectedRenewalDuration = from.renewalDurationMonths

      // Reset isInRentalPark
      this.selectedIsInRentalPark = this.rentalParkStatusFromPayablePath
    },

    // For the parent to change this value manually when search results change
    setRExIsInRentalPark (isInRentalPark) {
      this.selectedIsInRentalPark = isInRentalPark
    },

    validate () {
      // Require a rental park selection from the user for mobile homes
      if (
        this.showRentalParkStatus &&
        !this.selectedIsInRentalPark
      ) {
        this.rentalParkError = true
        return false
      }

      return true
    },
  },
}
</script>
