<template>
  <div>
    <div class="bg-white rounded-xl py-4 px-4 px-md-8">
      <h3>{{ $t('rex.search.by') }}</h3>
      <div class="container my-4">
        <div class="search-type-tabs row">
          <button
            v-for="searchTypeTab of searchTypeTabs"
            :key="searchTypeTab.key"
            :data-test="`${searchTypeTab.key}-tab`"
            class="search-type-tab col-sm btn p-5"
            :class="{
              active: searchType === searchTypeTab.searchType,
            }"
            @click="selectSearchType(searchTypeTab.searchType)"
          >
            <div class="media">
              <div class="mr-3 outer-circle rounded-circle">
                <svgicon
                  class="align-self-center border icon"
                  width="100%"
                  height="100%"
                  :fill="false"
                  :icon="searchTypeTab.icon"
                />
              </div>
              <div class="media-body text-wrap text-left align-self-center text-primary-link">
                {{ searchTypeTab.text }}
              </div>
            </div>
          </button>
        </div>
      </div>

      <div>
        <div v-if="searchType === 'PIN'">
          <div class="d-flex">
            <SearchBox
              id="pin-input"
              v-model="inputValues.pinString"
              class="w-100"
              name="pin-input"
              :placeholder="$t('rex.pin')"
              :show-icon="false"
              data-test="rex-pin"
              @search="search({searchType: 'PIN'})"
            />
            <ExampleImageButton
              v-if="showExampleModal"
              @open-modal="openExampleImageModal"
            />
          </div>

          <b-row
            class="mx-0 mt-2"
            align-h="end"
          >
            <ProgressButton
              :waiting="$wait.is('searchingPIN')"
              :variant="'primary'"
              data-test="pin-search-button"
              @click="search({searchType: 'PIN'})"
            >
              {{ $t('common.search') }}
            </ProgressButton>
          </b-row>
        </div>

        <div v-if="searchType === 'DOB'">
          <div class="d-flex">
            <SearchBox
              id="plate-input"
              v-model="inputValues.plateString"
              class="w-100"
              name="plate-input"
              :show-icon="false"
              :placeholder="$t('rex.search.plate_placeholder')"
              @search="search({searchType: 'DOB'})"
            />
            <ExampleImageButton
              v-if="showExampleModal"
              @open-modal="openExampleImageModal"
            />
          </div>

          <div class="my-2" />

          <div class="d-flex">
            <SearchBox
              ref="dobSearchBox"
              v-model="inputValues.dobString"
              class="w-100"
              name="dob-input"
              :placeholder="$t('rex.dob.long')"
              :show-icon="false"
              @search="search({searchType: 'DOB'})"
            />
            <ExampleImageButton
              v-if="showExampleModal"
              @open-modal="openExampleImageModal"
            />
          </div>

          <div class="mt-1">{{ $t("rex.date.format") }}</div>

          <b-row
            class="mx-0 my-0"
            align-h="end"
          >
            <ProgressButton
              class="mt-2"
              :waiting="$wait.is('searchingDOB')"
              :variant="'primary'"
              data-test="plate-search-button"
              @click="search({searchType: 'DOB'})"
            >
              {{ $t('common.search') }}
            </ProgressButton>
          </b-row>
        </div>
      </div>

      <Alert
        v-if="searchError"
        variant="danger"
        class="mt-4 mb-0"
        data-test="search-error"
        :dismissible="false"
        :show-icon="false"
      >
        <div v-dompurify-html="searchError" />
      </Alert>

    </div>

    <h4
      v-if="pageResults && pageResults.length"
      class="mt-3 mb-2"
    >{{ $t('rex.search.result') }}</h4>

    <div
      v-if="pageResults && !$wait.is('searchingPIN') && !$wait.is('searchingDOB')"
      class="rounded-xl pt-1 pb-2 px-0 mt-2"
    >

      <SearchResults
        :results="pageResults"
        :page="page"
        display-children-as-dropdown
        class="mt-2 mt-md-3"
        :use-pagination-api="usePaginationApi"
        :total="total"
        data-test="search-results"
        title=" "
        @page-change="paginate"
      >

        <template #result="{ payable, index }">
          <RExSearchResult
            :ref="payable.path"
            :payable="payable"
            display-children-as-dropdown
            @change-plate="data => replaceSearchResult(pageResults, index, data)"
            @change-rental-park-status="data => replaceSearchResult(pageResults, index, data)"
            @update-insurance="data => replaceSearchResult(pageResults, index, data)"
          >
            <!-- Pass slots through to child component -->
            <template #cornerIndicator="props">
              <slot
                name="cornerIndicator"
                v-bind="props"
              />
            </template>
            <template #payableActions="props">
              <slot
                name="payableActions"
                v-bind="props"
              />
            </template>
          </RExSearchResult>
        </template>

      </SearchResults>
    </div>

    <!-- Extended Search Results -->
    <h4
      v-if="pageResults && pageResults.length && pageRelatedResults && pageRelatedResults.length"
      id="related-results-header"
      class="mt-3 mb-2"
    >{{ $t('rex.search.related_results') }}</h4>
    <div
      v-if="pageRelatedResults && pageRelatedResults.length && !$wait.is('searchingRelated')"
      data-test="related-results-container"
    >
      <div
        class="rounded-xl pt-1 pb-2 px-0 mt-2"
      >
        <SearchResults
          :results="pageRelatedResults"
          :page="relatedPage"
          display-children-as-dropdown
          class="mt-2 mt-md-3"
          :total="totalRelated"
          :page-size="pageSize"
          data-test="search-results"
          @page-change="paginateRelated"
        >
          <template #result="{ payable, index }">
            <RExSearchResult
              :ref="payable.path"
              :payable="payable"
              display-children-as-dropdown
              @change-plate="data => replaceSearchResult(pageRelatedResults, index, data)"
              @change-rental-park-status="data => replaceSearchResult(pageRelatedResults, index, data)"
              @update-insurance="data => replaceSearchResult(pageRelatedResults, index, data)"
            >
              <!-- Pass slots through to child component -->
              <template #cornerIndicator="props">
                <slot
                  name="cornerIndicator"
                  v-bind="props"
                />
              </template>
              <template #payableActions="props">
                <slot
                  name="payableActions"
                  v-bind="props"
                />
              </template>
            </RExSearchResult>
          </template>

        </SearchResults>
      </div>
    </div>
    <div v-else-if="$wait.is('searchingRelated')">
      <LoadingBars />
    </div>

    <b-modal
      v-if="showExampleModal"
      id="example-image-modal"
      body-class="mt-3"
      ok-only
    >
      <p
        v-if="exampleImageText"
        v-dompurify-html="exampleImageText[$i18n.locale] || ''"
        data-test="search-example-text"
      />
      <b-img
        v-if="exampleImage"
        :src="exampleImage"
        :alt="exampleImageAltText[$i18n.locale] || ''"
        fluid
        data-test="search-example-image"
      />
    </b-modal>

    <b-modal
      id="passthrough-modal"
      centered
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      hide-header-close
      :hide-header="!prepopulatedCartRequestTimeout"
      :hide-footer="!prepopulatedCartRequestTimeout"
    >
      <LoadingBars v-if="!prepopulatedCartRequestTimeout" />
      <div v-else>
        {{ $t('cart.initialization_error') }}
      </div>
    </b-modal>

  </div>
</template>

<script>
import SearchBox from '@grantstreet/psc-vue/components/SearchBox.vue'
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import ExampleImageButton from './ExampleImageButton.vue'
import { formatDateOnInput } from '@grantstreet/psc-js/utils/date.js'
import { sentryException } from '../../sentry.ts'
import store from '../../store/index.ts'
import SearchResults from './SearchResults.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import RExSearchResult from './RExSearchResult.vue'

let setStorage
let getStorage
let removeStorage

const nopeStorage = () => {
  console.error('Cannot access local storage due to incognito window')
  const noOp = () => {}
  setStorage = noOp
  getStorage = noOp
  removeStorage = noOp
}

setStorage = (key, value) => {
  try {
    localStorage.setItem(key, value)
  }
  catch (error) {
    nopeStorage()
  }
}

getStorage = key => {
  try {
    return localStorage.getItem(key)
  }
  catch (error) {
    nopeStorage()
  }
}

removeStorage = key => {
  try {
    localStorage.removeItem(key)
  }
  catch (error) {
    nopeStorage()
  }
}

/**
 * Accepts array of payables returned by payables search. Will send sentry alert
 * if any payable has children that are marked as unpayable.
 * @param {Payable[]} payables payables being monitored
 * @param {Object} query search query parameters. Included in the sentry alert.
 */
function monitorPayableChildren (payables, query) {
  const badPayables = {} // parentPath: [badChildPaths]
  payables?.forEach(payable => {
    const unpayableChildren = payable?.unpayableChildren
      ?.map(child => child.path)
    if (!unpayableChildren.length) return
    badPayables[payable.path] = unpayableChildren
  })

  // Bail out if all children are payable
  if (!Object.keys(badPayables).length) return

  // Generate sentry message
  let message =
        'Rexhub search returned payable(s) with unpayable children.' +
        ' All children are expected to be payable.\n' +
        'Search args: ' +
        JSON.stringify(query) + '\n'

  Object.entries(badPayables).forEach(([parent, children]) => {
    message += `Parent path: ${parent}\n` +
          '\tunpayable children paths:' +
          JSON.stringify(children)
  })

  sentryException(message)
}

export default {
  emits: ['prepopulate', 'clearPrePopulateCartWarnings'],
  components: {
    SearchBox,
    ProgressButton,
    Alert,
    LoadingBars,
    ExampleImageButton,
    SearchResults,
    RExSearchResult,
  },

  mixins: [scrollToMixin],

  props: {
    payablesAdaptor: {
      type: String,
      required: true,
    },

    optionalQueryParams: {
      type: Object,
      default: function () {
        return {}
      },
    },

    exampleImage: {
      type: String,
      default: '',
    },

    exampleImageText: {
      type: Object,
      default: () => null,
    },

    exampleImageAltText: {
      type: Object,
      default: () => ({}),
    },
    pageSize: {
      type: Number,
      default: 10,
    },

  },

  data () {
    const searchInputs = ['dobString', 'plateString', 'pinString']
    return {
      searchInputs,
      inputValues: searchInputs.reduce((values, input) => {
        values[input] = ''
        return values
      }, {}),

      // Handle responses from payables adaptor
      searchError: null,
      results: null,
      relatedResults: [],

      // Used to indicate we reloaded page and should search again immediately
      autoSearch: false,
      page: 1,
      relatedPage: 1,

      searchType: '',

      // Used for prepopulated carts' Passthrough Modal to show time out message
      prepopulatedCartRequestTimeout: false,

      // TODO:
      usePaginationApi: false,
      limit: this.pageSize,
      total: 0,
      totalRelated: 0,
    }
  },

  computed: {

    pageResults () {
      return this.results?.[this.page]
    },

    pageRelatedResults () {
      return this.relatedResults?.[this.relatedPage]
    },

    sharedQueryParams () {
      return {
        client: store.state.payablesConfig.client,
        site: store.state.payablesConfig.site,
        type: 'renewal',
      }
    },

    contactCountyText () {
      return store.state.payablesConfig.contactCountyText?.[this.$i18n.locale] || ''
    },

    showExampleModal () {
      return this.exampleImage || (this.exampleImageText?.en || this.exampleImageText?.es)
    },

    defaultDeliveryMethod () {
      const deliveryMethodIsEnabled = store.state.payablesConfig.useDelivery
      const isShippingOnly = !deliveryMethodIsEnabled || !store.state.payablesConfig?.enableRExHubPickUp
      const chargeMailFee = store.state.payablesConfig.renewalServiceFee.includes('chargeMailFee')
      const chargePickUpFee = store.state.payablesConfig.renewalServiceFee.includes('chargePickUpFee')
      return isShippingOnly || (chargeMailFee && chargePickUpFee) ? 'shipping' : 'none'
    },

    searchTypeTabs () {
      return [
        {
          key: 'pin',
          text: this.$t('rex.search.by_pin'),
          icon: 'document',
          searchType: 'PIN',
        },
        {
          key: 'plate',
          text: this.$t('rex.search.by_plate'),
          icon: 'license-plate',
          searchType: 'DOB',
        },
      ]
    },
  },

  created () {
    // Reset any warnings we might have had from failure to add items to a
    // pre-populated cart.
    this.$emit('clearPrePopulateCartWarnings')

    const urlSearchParams = new URLSearchParams(window.location.search)
    // Can get multiple pins from reminders
    const pins = urlSearchParams.getAll('pin')
    // Can only get one lpn from CNF redirect
    const lpn = urlSearchParams.get('lpn')
    // Can only get one dob from CNF redirect
    const dob = urlSearchParams.get('dob')
    this.makePrepopulatedCart({ pins, lpn, dob })

    // Start on same page as previous search
    // Don't clear pagination, so state is maintained through subsequent loads
    this.page = Number(getStorage(window.location.href)) || this.page
    this.relatedPage = Number(getStorage(window.location.href + 'relatedPage')) || this.relatedPage
  },

  beforeMount () {
    // When checkout is complete (or whenever requested) we should clear any
    // saved search data.
    store.state.eventBus?.$on('cart.checkout', this.clearSearches)
  },

  mounted () {
    if (!this.autoSearch) {
      return
    }

    this.searchType = getStorage(window.location.href + 'searchType')
    removeStorage(window.location.href + 'searchType')

    if (this.searchType) {
      this.search({ autoSearch: true, searchType: this.searchType })
    }
  },

  beforeUnmount () {
    // Don't forget to remove handlers
    store.state.eventBus?.$off('cart.checkout', this.clearSearches)
    window.removeEventListener('resize', this.handleResize)
  },

  watch: {
    'inputValues.dobString' (newRawValue, oldValue) {
      // Get cursor positions from the DOB input
      const dobInput = this.$refs.dobSearchBox
      const cursorEnd = dobInput.getSelectionEnd()

      // What is the user doing
      const isDeleting = newRawValue.length < oldValue.length
      const isDeletingFromEnd = isDeleting && cursorEnd === oldValue.length - 1
      const isEditingYear = cursorEnd > 6

      // If the user is deleting from the end of the string, they are deleting
      // from an already formatted DOB value so just let the new value through
      if (isDeletingFromEnd) {
        this.inputValues.dobString = newRawValue
        return
      }

      // Otherwise, we need to do some work
      this.$nextTick(() => {
        // Don't allow characters to be inserted in the middle of a full DOB string
        if (newRawValue.length > 10) {
          this.inputValues.dobString = oldValue
          return
        }

        // Ensure MM/DD/YYYY format
        const newValue = formatDateOnInput(newRawValue)
        this.inputValues.dobString = newValue

        // Place the resulting cursor position where the user expects it
        // Reactivity on the input value would default the cursor to the end of the string
        // That can be confusing if the user is editing the middle of the DOB string
        this.$nextTick(() => {
          let newCursorEnd = cursorEnd

          // Addition/Removal of text - move cursor by the length difference
          const valueLengthDiff = newValue.length - oldValue.length
          newCursorEnd += valueLengthDiff

          // Put the cursor position where it should be when modifying the year
          if (isEditingYear) {
            if (isDeleting) {
              newCursorEnd++
            }
            else {
              newCursorEnd--
            }
          }

          // Move cursor after the auto-inserted slash if we are before it
          if (newValue[newCursorEnd] === '/') {
            newCursorEnd++
          }

          dobInput.setSelectionRange(newCursorEnd, newCursorEnd)
        })
      })
    },
  },

  methods: {
    formatDateOnInput,

    storePage (page) {
      // Store pagination index
      setStorage(window.location.href, JSON.stringify(page))
      this.page = page
    },

    storeRelatedPage (page) {
      // Store pagination index
      setStorage(window.location.href + 'relatedPage', JSON.stringify(page))
      this.relatedPage = page
    },

    async selectSearchType (searchType) {
      this.searchError = null
      this.searchType = searchType

      if (searchType === 'PIN') {
        this.scrollTo('#pin-input')
      }
      if (searchType === 'DOB') {
        this.scrollTo('#plate-input')
      }
    },

    async paginate (page) {
      if (
        this.usePaginationApi &&
        // If we already have up through the last result cached, don't search
        !this.results?.[page]
      ) {
        await this.search({ page, searchType: this.searchType })
      }

      // Set page after search has completed. This is what triggers children to
      // update
      this.storePage(page)
    },

    async paginateRelated (page) {
      this.scrollTo('#related-results-header')
      // Set page after search has completed. This is what triggers children to
      // update
      this.storeRelatedPage(page)
    },

    clearSearches () {
      // Clear stored pagination index
      removeStorage(window.location.href)
      removeStorage(window.location.href + 'relatedPage')

      // Clear current state
      this.results = null
      this.searchError = null
      this.relatedResults = null
    },

    makePrepopulatedCart ({ pins, lpn, dob }) {
      const sharedParams = {
        // eslint-disable-next-line camelcase
        delivery_method: this.defaultDeliveryMethod,
      }

      if (pins?.length) {
        pins = [...new Set(pins)]
        this.prepopulateCart(pins.map(pin => this.searchPayables({ query: { pin, ...sharedParams } })))
      }
      else if (lpn && dob) {
        this.prepopulateCart([this.searchPayables({ query: { lpn, dob, ...sharedParams } })])
      }
    },

    getLocalizedHeader (id, type) {
      return {
        key: `rex.prepopulate_warnings.general_header.${type}`,
        data: { id },
      }
    },

    async prepopulateCart (searches) {
      this.$nextTick(() => this.$bvModal.show('passthrough-modal'))
      // Show cart error if user hasn't been redirected to checkout within 10
      // seconds.
      setTimeout(() => {
        this.prepopulatedCartRequestTimeout = true
      }, 10_000)

      const values = await Promise.all(searches)

      const payablesToAdd = []
      const unrenewableMessages = []
      const insuranceAffidavitRenewals = []
      for (const { query, results, error } of values) {
        const idFromQuery = query.lpn || query.pin

        // The registration is unrenewable and we have no data for it
        if (error) {
          unrenewableMessages.push({
            localizedHeader: this.getLocalizedHeader(idFromQuery, query.lpn ? 'plate' : 'pin'),
            message: this.stripContactCountyText(error),
          })
          continue
        }

        // The registration could not be found
        if (!results?.length) {
          unrenewableMessages.push({
            localizedHeader: this.getLocalizedHeader(idFromQuery, query.lpn ? 'plate' : 'pin'),
            localizedMessage: {
              key: 'rex.prepopulate_warnings.unknown_registration_message',
            },
          })
          continue
        }

        const payable = results[0]

        let payableChildren = []
        if (payable.childPayables && payable.childPayables.length) {
          payableChildren = payable.childPayables.filter(child => child.isPayable)
        }
        else if (payable.paymentChoices && payable.paymentChoices.length) {
          // Payment choices are payable if present
          payableChildren = payable.paymentChoices
        }

        const idFromPayable = payable.customParameters.registrationDetail.licensePlateNumber
        const isPayableMessage = this.stripContactCountyText(payable.isPayableMessage.html_display)

        // If we have payable children, add the first child (shortest renewal
        // duration) to the cart
        if (payableChildren.length) {
          payablesToAdd.push({
            path: payableChildren[0].path,
            amount: payableChildren[0].amount,
            quantity: 1,
            userParameters: {},
            details: payableChildren[0].raw,
          })

          // Carry any warnings over to the cart even if payable
          if (isPayableMessage) {
            unrenewableMessages.push({
              localizedHeader: {
                key: 'rex.prepopulate_warnings.notice_header',
                data: { id: idFromPayable },
              },
              message: isPayableMessage,
            })
          }

          continue
        }

        // The renewal requires an insurance affidavit to be payable
        if (payable.customParameters?.prompt_inline_insurance_affidavit) {
          insuranceAffidavitRenewals.push({
            id: idFromQuery,
            type: query.lpn ? 'plate' : 'pin',
            plate: payable.customParameters?.registrationDetail?.licensePlateNumber,
          })
          continue
        }

        // The payable is unrenewable for some reason
        unrenewableMessages.push({
          localizedHeader: this.getLocalizedHeader(idFromPayable, 'plate'),
          message: isPayableMessage,
          localizedMessage: {
            key: 'rex.prepopulate_warnings.nonrenewable_message',
          },
        })
      }

      // If we have insurance affidavit renewals
      // build up a custom message so the user knows what to do.
      if (insuranceAffidavitRenewals.length) {
        // Sort of a gross dynamic list buildup...
        let insAffMessage = '<ul>'

        for (const insAff of insuranceAffidavitRenewals) {
          // This bit of logic ensures we display information we have about the vehicle
          if (insAff.type === 'plate') {
            // I am not sure that it is possible that we get here from an e-reminder but since the LPN case
            // is handled above I decided to handle it here.
            insAffMessage += `<li>LPN: ${insAff.id}</li>`
          }
          else if (insAff.plate) {
            // If we have a plate attached to the payable, display it with the PIN to help
            // the customer identify the problematic vehicle.
            insAffMessage += `<li>PIN: ${insAff.id} (LPN: ${insAff.plate})</li>`
          }
          else {
            // Otherwise just display the PIN and the customer can perform a search using that.
            insAffMessage += `<li>PIN: ${insAff.id}</li>`
          }
        }

        // This portion currently will not be translated if the localization is changed when the error is visible.
        insAffMessage += `</ul>${this.$t('rex.prepopulate_warnings.insurance_affidavit_footer', {
          site: store.state.payablesConfig.site,
        })}`

        // Finally push our custom message onto the prepopulated cart
        // warnings array so that it will be displayed
        unrenewableMessages.push({
          localizedHeader: {
            key: 'rex.prepopulate_warnings.insurance_affidavit_header',
          },
          message: insAffMessage,
        })
      }

      this.$emit('prepopulate', {
        items: payablesToAdd,
        warnings: unrenewableMessages,
      })
    },

    async search ({ autoSearch, searchType = this.searchType, page } = {}) {
      // Nothing to do without searchType
      if (!searchType) {
        return
      }

      // If a searchType is passed then set it
      if (searchType) {
        this.searchType = searchType
      }

      // Don't start another search if there is already one in progress for the same search type
      if (this.$wait.is(`searching${searchType}`)) {
        return
      }

      this.$wait.start(`searching${searchType}`)

      this.searchError = null

      let query
      // Set specific query data
      if (searchType === 'DOB') {
        // Store and clear values if this is a new search
        if (typeof page !== 'number') {
          this.inputValues.pinString = ''
        }

        query = {
          lpn: this.inputValues.plateString,
          dob: this.inputValues.dobString,
        }
      }
      else {
        // Store and clear values if this is a new search
        if (typeof page !== 'number') {
          this.inputValues.plateString = ''
          this.inputValues.dobString = ''
        }

        query = {
          pin: this.inputValues.pinString,
        }
      }

      query = {
        ...query,
        // eslint-disable-next-line camelcase
        delivery_method: this.defaultDeliveryMethod,
      }

      // Is the user actively paging through an existing search?
      const paginating = typeof page === 'number'

      if (!paginating) {
        if (!autoSearch) {
          this.storePage(1)
        }
        // Set target page for new searches.
        page = this.page
      }

      // Reset search
      this.results = [null, []]
      this.relatedResults = []

      // TODO: Infer api pagination compatibility from data and use that to
      // decide whether to paginate
      const { results, error, total } = await this.searchPayables({
        query,
        options: {
          // eslint-disable-next-line camelcase
          ignore_cache: true,
        },
        ...(this.usePaginationApi ? {
          limit: this.limit,
          // Pages are 1 indexed
          offset: this.pageSize * (page - 1),
        } : {}),
      })

      this.total = total

      if (error) {
        this.results = null
        this.searchError = this.fillConfigurableMessage(error)
      }
      else if (this.usePaginationApi) {
        // TODO: Test this when the api supports it

        // Reset results on new searches (or set initial value)
        if (!paginating || !this.results) {
          this.results = []
        }
        this.results[page] = results
      }
      else {
        // Build out structure for pages
        // Pages are 1 indexed. Initial value is an empty first page.
        const pagedResults = [null, []]
        let i = 0
        while (i < results.length) {
        // Pages are 1 indexed
          const pageCursor = Math.floor(i / this.pageSize) + 1
          if (!pagedResults[pageCursor]) {
            pagedResults[pageCursor] = []
          }

          // Do some extra formatting
          if (results[i].isPayableMessage) {
          // eslint-disable-next-line camelcase
            results[i].raw.is_payable_message.html_display = this.fillConfigurableMessage(results[i].isPayableMessage.html_display)
          }

          pagedResults[pageCursor][i % this.pageSize] = results[i]
          i++
        }
        this.results = pagedResults
      }

      this.$wait.end(`searching${searchType}`)

      // Fire off an asynchronous related search
      this.searchRelated()
    },

    async searchPayables (data) {
      let results
      let total
      let error
      try {
        const query = {
          ...this.sharedQueryParams,
          ...data.query,
        }
        const options = data.options || {}
        ;({ payables: results, total } = await store.dispatch('searchPayables', {
          payablesAdaptor: this.payablesAdaptor,
          data: {
            ...data,
            query,
            options,
          },
          language: this.$i18n.locale,
        }))
        // alert sentry if any children are unpayable
        monitorPayableChildren(results, query)
      }
      catch (error_) {
        error = error_.response?.data?.displayMessage || this.$t('api.error')
      }
      return {
        query: data.query,
        results,
        total,
        error,
      }
    },

    // Performs an extended search to pull in vehicles we think
    // are related to the renewal that the customer originally searched for.
    async searchRelated () {
      if (!store.state.payablesConfig.extendVehicleRegistrationSearch) {
        return
      }

      this.$wait.start('searchingRelated')
      this.relatedResults = [null, []]
      this.storeRelatedPage(1)
      const pins = []

      // First we need to get the customer number(s) to search by
      const renewal = this.pageResults?.[0]

      if (!this.pageResults || !renewal) {
        this.$wait.end('searchingRelated')
        return
      }

      const customerNumbers = renewal.customParameters.customer_numbers
      const regNumber = renewal.customParameters.registrationDetail.registrationNumber

      if (!customerNumbers || !customerNumbers.length) {
        this.$wait.end('searchingRelated')
        return
      }

      // Perform a payables search for each of the customer numbers
      // we have, pass the customer number and the registration number
      // of the renewal result.
      const relatedRenewals = await Promise.all(
        customerNumbers.map(
          customerNumber => (
            this.searchPayables({
              query: {
                ...this.sharedQueryParams,
                // eslint-disable-next-line camelcase
                customer_number: customerNumber,
                // eslint-disable-next-line camelcase
                registration_number: regNumber,
                type: 'related_renewals',
              },
              options: {
                // eslint-disable-next-line camelcase
                ignore_cache: true,
                // eslint-disable-next-line camelcase
                dont_cache: true,
              },
              // Pagination stuff is copied from search method
              ...(this.usePaginationApi ? {
                limit: this.limit,
                // Pages are 1 indexed
                offset: this.pageSize * (page - 1),
              } : {}),
            })
          )))

      // Get the pins of the related renewals so that we can search
      // for the full renewal below.
      for (const { results } of relatedRenewals) {
        for (const res of results) {
          const pin = res.customParameters.pinNumber
          if (pin) {
            pins.push(pin)
          }
        }
      }

      // Get related payables and store them as the relatedResults for rendering.
      const relatedPayables = await Promise.all(
        pins.map(pin => (
          this.searchPayables({
            query: {
              ...this.sharedQueryParams,
              pin,
              type: 'renewal',
              // eslint-disable-next-line camelcase
              require_res: 1,
              // eslint-disable-next-line camelcase
              delivery_method: this.defaultDeliveryMethod,
            },
            options: {
              // eslint-disable-next-line camelcase
              ignore_cache: true,
            },
            // Pagination stuff is copied from search method
            ...(this.usePaginationApi ? {
              limit: this.limit,
              // Pages are 1 indexed
              offset: this.pageSize * (page - 1),
            } : {}),
          })
        )))

      let allResults = []
      for (const { results } of relatedPayables) {
        for (const res of results) {
          allResults.push(res)
        }
      }

      allResults = this.sortRenewals(allResults)

      const pagedResults = [null, []]
      let i = 0
      while (i < allResults.length) {
      // Pages are 1 indexed
        const pageCursor = Math.floor(i / this.pageSize) + 1
        if (!pagedResults[pageCursor]) {
          pagedResults[pageCursor] = []
        }

        // Do some extra formatting
        if (allResults[i].isPayableMessage) {
          // eslint-disable-next-line camelcase
          allResults[i].raw.is_payable_message.html_display = this.fillConfigurableMessage(allResults[i].isPayableMessage.html_display)
        }

        pagedResults[pageCursor][i % this.pageSize] = allResults[i]
        i++
      }

      this.relatedResults = pagedResults

      this.totalRelated = allResults.length
      this.$wait.end('searchingRelated')
    },

    // TODO: Is there any reason this couldn't be combined with
    // fillConfigurableMessage?
    stripContactCountyText (message) {
      if (!message) {
        return ''
      }

      return message.replace(/\s*#CONTACT_COUNTY_TEXT#\s*/, '')
    },

    // Fills in the configurable sections of the given message
    fillConfigurableMessage (message) {
      if (!message) {
        return
      }

      // List of controlled searches 'n replaces
      return [
        {
          search: /#CONTACT_COUNTY_TEXT#/g,
          replace: this.contactCountyText,
        },
      ].reduce(
        (message, { search, replace }) => message.replace(search, replace),
        message,
      )
    },

    openExampleImageModal () {
      this.$bvModal.show('example-image-modal')
    },

    async replaceSearchResult (results, index, { newPayable, selectedRenewalDuration, selectedIsInRentalPark }) {
      // $set is necessary because we use nested arrays for pagination. The
      // child arrays aren't tracked by vue
      this.$set(results, index, newPayable)

      if (!selectedRenewalDuration && selectedIsInRentalPark !== undefined) {
        return
      }
      // Pass previous selections back to the new component on the next tick,
      // after DOM is updated with the new payable
      await this.$nextTick()
      if (selectedRenewalDuration) {
        this.$refs[newPayable.path].setRExRenewalDuration(selectedRenewalDuration)
      }
      if (selectedIsInRentalPark !== undefined) {
        this.$refs[newPayable.path].setRExIsInRentalPark(selectedIsInRentalPark)
      }
    },

    // If a payable doesn't have child payables, it isn't eligible for renewal.
    // Put ineligible renewals at the end, also sort alphabetically.
    // The exception to this is a vehicle that is requesting insurance information.
    sortRenewals (payables) {
      return payables.sort((a, b) => {
        const plateA = a.customParameters.registrationDetail.licensePlateNumber
        const plateB = b.customParameters.registrationDetail.licensePlateNumber

        const payA = a.childPayables.length > 0 || a.customParameters?.prompt_inline_insurance_affidavit
        const payB = b.childPayables.length > 0 || b.customParameters?.prompt_inline_insurance_affidavit

        if (!payA || !payB) {
          if (payA) {
            return -1
          }
          if (payB) {
            return 1
          }
        }

        if (plateA < plateB) {
          return -1
        }
        else if (plateA > plateB) {
          return 1
        }

        return 0
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.search-type-tabs {
  gap: 1rem;
}

.search-type-tab {
  border-radius: $border-radius !important;
  background-color: $light !important;
}

.search-type-tab.active, .search-type-tab:hover {
  border: $border-width solid $primary;
}

.search-type-tab .media-body {
  font-weight: bold;
}

.search-type-tab .outer-circle {
  padding: 0.75rem;
  border-color: $off-white;
  background-color: $off-white;
}

.outer-circle {
  width: 3.5rem;
  height: 3.5rem;
}

.search-type-tab[data-test=plate-tab] .icon {
  stroke-width: 1px !important;
}

</style>
