// This is the main entrypoint for the announcements public widgets. For the
// admin widget, see gsg-announcements-admin-ui/src/main.js.

import Vue, { createApp } from 'vue'
import Vuex from 'vuex'
import { sentryException, vueErrorHandler } from './sentry.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import APIClient from './api-client.js'
import RequestApi from '@grantstreet/request-api'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import installAnnouncements from './install.js'
import Banners from './components/public/Banners.vue'
import NotificationPopper from './components/public/NotificationPopper.vue'
import FooterWidget from './components/public/FooterWidget.vue'
import FooterTitle from './components/public/FooterTitle.vue'

// Import popper css so it's in the pubic widget bundle
import 'floating-vue/dist/style.css'

// The PayHub store is required so that we have access to the configs.
//
// We can't list @grantstreet/govhub-vue as a package.json dependency to avoid a
// circular dependency. See PSC-9153.
// eslint-disable-next-line import/no-extraneous-dependencies, node/no-extraneous-import
import store from '@grantstreet/govhub-vue/src/store/index.js'
import { installConfig, loadConfig } from '@grantstreet/psc-config'

import { initWidgetEnvWithFallback } from '@grantstreet/psc-environment/environment.js'

Vue.use(VueSVGIcon)
Vue.use(Vuex)

export const attach = async (primaryElSelector, {
  environment,
  client,
  site,
  language,
  component: primaryComponentName,
  secondaryComponent: secondaryComponentName,
  secondaryElSelector,
  tags,
  debug,
} = {}) => {
  try {
    initWidgetEnvWithFallback({
      explicitEnvironment: environment,
      urlForLegacyEnvironment: window.location.hostname.replace(/[0-9]/g, ''),
      diagApp: 'announcements-public',
      RequestApi,
      exceptionLogger: sentryException,
    })
  }
  catch (rawError) {
    const error = new Error(`Announcements Error: ${rawError.message}`)
    sentryException(error)
    throw error
  }

  let primaryComponent
  let secondaryComponent

  if (primaryComponentName === 'Banners') {
    primaryComponent = Banners
  }
  else if (primaryComponentName === 'NotificationPopper') {
    primaryComponent = NotificationPopper
  }
  else if (primaryComponentName === 'FooterWidget') {
    primaryComponent = FooterWidget
  }
  else {
    throw new Error('`component` must be one of "Banners", "NotificationPopper", "FooterWidget", or "FooterTitle"')
  }

  if (secondaryElSelector && secondaryComponentName) {
    if (secondaryComponentName === 'FooterTitle') {
      secondaryComponent = FooterTitle
    }
    else {
      throw new Error('`secondaryComponent`, if defined, must be "FooterTitle"')
    }
  }

  // If this is the first time attach has been called initialize the app
  // This is so that it can be called for different features
  if (!store.state.Announcements) {
    // Install config module
    installConfig({
      logDiagnostics: () => {},
    })

    await loadConfig({ client, site })

    if (language) {
      i18n.global.locale.value = language
    }

    const ok = await installAnnouncements(Vue, {
      store,
      api: APIClient,
    })

    if (!ok) {
      return
    }
  }

  createApp(primaryComponent, {
    tags,
    debug,
  })
    .use(vueErrorHandler)
    .use(store)
    .use(i18n)
    .mount(primaryElSelector)

  if (!secondaryComponent) {
    return
  }

  createApp(secondaryComponent)
    .use(vueErrorHandler)
    .use(store)
    .use(i18n)
    .mount(secondaryElSelector)
}
