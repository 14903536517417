<template>
  <OverlaySidebar
    :id="id"
    right
    :title="$t('rex.insurance_affidavit.label')"
    :on-show="() => isVisible = true"
    :on-hide="() => isVisible = false"
    data-test="insurance-affidavit-sidebar"
  >
    <template #default>
      <div>
        <div class="px-6 pt-2">
          <p>{{ $t('rex.insurance_affidavit.call_to_action') }}</p>
        </div>
        <div class="px-6 pb-2">
          <p
            v-dompurify-html="$t('rex.insurance_affidavit.commercial_vehicle.text')"
            class="mb-0"
          />
          <b-button
            v-if="commercialVehicleInfo"
            size="sm"
            variant="link"
            class="text-decoration-none p-0 m-0"
            @click="displayCommercialVehicleInfo = !displayCommercialVehicleInfo"
          >
            {{ $t('rex.insurance_affidavit.follow_instructions') }}
          </b-button>
        </div>
        <div
          v-if="displayCommercialVehicleInfo"
          class="py-2 mx-6 px-6 rounded-xl"
          style="background-color: #F8F8F8"
        >
          <b-row class="align-items-center justify-content-between">
            <h4 class="mb-0">{{ $t('rex.insurance_affidavit.commercial_vehicle.title') }}</h4>
            <b-button
              variant="link"
              class="text-decoration-none"
              @click="displayCommercialVehicleInfo = false"
            >
              <svgicon
                :fill="false"
                icon="x"
                width="16px"
                height="16px"
              />
            </b-button>
          </b-row>
          <p v-dompurify-html="commercialVehicleInfo" />
        </div>
        <div class="px-6 pb-2">
          <p
            v-dompurify-html="$t('rex.insurance_affidavit.military_personnel.text')"
            class="mb-0"
          />
          <b-button
            v-if="militaryPersonnelInfo"
            size="sm"
            variant="link"
            class="text-decoration-none p-0 m-0"
            @click="displayMilitaryPersonnelInfo = !displayMilitaryPersonnelInfo"
          >
            {{ $t('rex.insurance_affidavit.follow_instructions') }}
          </b-button>
        </div>
        <div
          v-if="displayMilitaryPersonnelInfo"
          class="py-2 mx-6 px-6 rounded-xl"
          style="background-color: #F8F8F8"
        >
          <b-row class="align-items-center justify-content-between">
            <h4 class="mb-0">{{ $t('rex.insurance_affidavit.military_personnel.title') }}</h4>
            <b-button
              variant="link"
              class="text-decoration-none"
              @click="displayMilitaryPersonnelInfo = false"
            >
              <svgicon
                :fill="false"
                icon="x"
                width="16px"
                height="16px"
              />
            </b-button>
          </b-row>
          <p v-dompurify-html="militaryPersonnelInfo" />
        </div>
        <div class="px-6">
          <p>{{ $t('rex.insurance_affidavit.disclaimer') }}</p>
        </div>
        <div class="px-6 py-2">
          <b-row>
            <b-col>
              <h5>{{ $t('rex.insurance_affidavit.for_vehicle') }}</h5>
            </b-col>
            <b-col cols="8">
              <div>
                <div>{{ renewal.displayName }}</div>
                <div>{{ renewal.description }}</div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="px-6 py-2">
          <b-row
            v-if="useVIN"
            class="my-1"
          >
            <b-col cols="4">
              <div>{{ $t('rex.insurance_affidavit.vin') }}*</div>
            </b-col>
            <b-col cols="8">
              <b-form-input
                v-model="model.vin"
                data-test="insurance-vin-input"
                :disabled="readOnly || (savedFieldsAreValid && savedFields.vin === model.vin)"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col>
              <div>{{ $t('rex.insurance_affidavit.company') }}*</div>
            </b-col>
            <b-col cols="8">
              <b-form-input
                v-model="model.companyName"
                data-test="insurance-company-name-input"
                :disabled="readOnly || (savedFieldsAreValid && savedFields.companyName === model.companyName)"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col>
              <div>{{ $t('rex.insurance_affidavit.policy_number') }}*</div>
            </b-col>
            <b-col cols="8">
              <b-form-input
                v-model="model.policyNumber"
                data-test="insurance-policy-number-input"
                :disabled="readOnly || (savedFieldsAreValid && savedFields.policyNumber === model.policyNumber)"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row class="my-1">
            <b-col>
              <b-row class="px-3">
                <div>{{ $t('rex.insurance_affidavit.company_code') }}*</div>
                <ExampleImageButton
                  @open-modal="openCompanyCodeModal"
                />
                <b-modal
                  :id="companyCodeModalId"
                  size="xl"
                  body-class="mt-3"
                  ok-only
                >
                  <b-img
                    v-if="companyCodeImage"
                    :src="companyCodeImage"
                    alt="company code location"
                    fluid
                    data-test="company-code-example-image"
                  />
                </b-modal>
              </b-row>
            </b-col>
            <b-col cols="8">
              <b-form-input
                v-model="model.companyCode"
                data-test="insurance-company-code-input"
                :disabled="readOnly || (savedFieldsAreValid && savedFields.companyCode === model.companyCode)"
                type="text"
                maxlength="5"
              />
              <b-form-text>{{ $t('rex.insurance_affidavit.company_code_helper') }}</b-form-text>
            </b-col>
          </b-row>
          <b-row
            v-if="useSignature"
            class="my-1"
          >
            <b-col>
              <div>{{ $t('rex.insurance_affidavit.signature') }}*</div>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <div v-if="isVisible && (savedFields.signatureId === '' || savedFields.signatureId !== model.signatureId)">
                <SignatureUploadWidget
                  data-test="insurance-signature-input"
                  source-tag="insurance-affidavit-signature"
                  :retain-until="retainSignatureUntil"
                  :exception-logger="sentryException"
                  @update:model-value="model.signatureId = $event"
                  @update:is-processing="handleProcessingSignature"
                />
              </div>
              <b-row
                v-if="savedFields.signatureId && savedFields.signatureId === model.signatureId"
                class="px-3"
              >
                <div>
                  {{ $t('rex.insurance_affidavit.signature_captured') }}
                </div>
                <svgicon
                  :fill="false"
                  icon="check-circle"
                  class="align-self-center text-success mx-1"
                />
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2 justify-content-end">
            <b-col cols="8">
              <small>* {{ $t('required.default') }}</small>
            </b-col>
          </b-row>
        </div>
        <div class="px-6 py-2 border-top">
          <b-row class="mt-1">
            <b-col>
              <p v-dompurify-html="$t('rex.insurance_affidavit.criminal_warning')" />
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <p>
                {{ $t('rex.insurance_affidavit.statute_reference') }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="my-3 px-3 py-2 d-flex justify-content-end">
        <b-button
          class="mx-1"
          variant="outline-primary"
          @click="cancel"
        >
          {{ readOnly || savedFieldsAreValid ? $t('rex.close') : $t('common.cancel') }}
        </b-button>
        <b-button
          v-if="!readOnly && !savedFieldsAreValid"
          :disabled="!fieldsAreValid(model)"
          class="mx-1"
          variant="primary"
          @click="submit"
        >
          {{ $t('rex.insurance_affidavit.submit') }}
        </b-button>
        <b-button
          v-if="!readOnly && savedFieldsAreValid"
          class="mx-1"
          variant="primary"
          @click="reset"
        >
          {{ $t('rex.insurance_affidavit.reset') }}
        </b-button>
      </div>
    </template>
  </OverlaySidebar>
</template>

<script>
import OverlaySidebar from '@grantstreet/psc-vue/components/OverlaySidebar.vue'
import ExampleImageButton from '../search/ExampleImageButton.vue'
import store from '../../store/index.ts'
import { SignatureUploadWidget } from '@grantstreet/uploads'
import { sentryException } from '../../sentry.ts'

const ONE_YEAR_MS = 365 * 24 * 60 * 60 * 1000

export default {
  emits: ['inline-insurance-submitted'],
  components: {
    OverlaySidebar,
    ExampleImageButton,
    SignatureUploadWidget,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    renewal: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      model: {
        companyName: this.renewal.customParameters?.insurance?.company_name || '',
        policyNumber: this.renewal.customParameters?.insurance?.policy_number || '',
        companyCode: this.renewal.customParameters?.insurance?.company_code || '',
        vin: this.renewal.customParameters?.insurance?.vin || '',
        signatureId: this.renewal.customParameters?.insurance?.signature_id || '',
      },

      companyCodeModalId: 'company-code-example-image-modal-' + this.id,
      companyCodeImage: 'https://psc.grantstreet-cdn.com/inline-insurance-affidavit/insurance-company-code-help.png',

      // Handle display of extra help info for commercial and military vehicles
      // The dropdown sections controlled by these values will not display if
      // the applicable insuranceAffidavitInfo setting is blank.
      displayCommercialVehicleInfo: false,
      displayMilitaryPersonnelInfo: false,

      // Signature ID Handling
      // This is used to determine whether or not to load the iFrame associated
      // with the signature widget
      isVisible: false,

      // The combination of signatureId and the value for isSignatureProcessing
      // will determine whether or not our data validation is successful for
      // customers to submit the affidavit.
      isSignatureProcessing: false,

      isReset: false,
      sentryException,

      // We want to retain the signature for 2 years
      retainSignatureUntil: new Date(Date.now() + 2 * ONE_YEAR_MS).toISOString(),
    }
  },

  computed: {
    savedFields () {
      return {
        companyName: this.renewal.customParameters?.insurance?.company_name || '',
        policyNumber: this.renewal.customParameters?.insurance?.policy_number || '',
        companyCode: this.renewal.customParameters?.insurance?.company_code || '',
        vin: this.renewal.customParameters?.insurance?.vin || '',
        signatureId: this.renewal.customParameters?.insurance?.signature_id || '',
      }
    },

    // TODO: PSC-20148 - This all really needs to be done with vuelidate.
    // $dirty, $invalid, and $touch() would make this trivial, more
    // intelligible, and more maintainable
    savedFieldsAreValid () {
      return !this.isReset && this.fieldsAreValid(this.savedFields)
    },

    // Values for commercial and military personnel help dropdown text boxes.
    commercialVehicleInfo () {
      return store.state.payablesConfig.insuranceCommercialVehicleHelp?.[this.$i18n.locale]
    },
    militaryPersonnelInfo () {
      return store.state.payablesConfig.insuranceMilitaryPersonnelHelp?.[this.$i18n.locale]
    },

    // Values for whether VIN and signature inputs are displayed on the
    // affidavit
    useVIN () {
      return store.state.payablesConfig.insuranceVIN
    },
    useSignature () {
      return store.state.payablesConfig.insuranceSignature
    },
  },

  methods: {
    fieldsAreValid (fields) {
      if (
        fields.companyName === '' ||
        fields.policyNumber === '' ||
        fields.companyCode === ''
      ) {
        return false
      }

      if (this.useVIN && fields.vin === '') {
        return false
      }

      if (this.useSignature && (
        fields.signatureId === '' ||
        this.isSignatureProcessing
      )) {
        return false
      }

      return true
    },

    submit () {
      if (!this.fieldsAreValid(this.model)) {
        return
      }

      this.$emit('inline-insurance-submitted', this.model)

      this.$root.$emit('bv::toggle::collapse', this.id)
    },

    cancel () {
      // On cancel, change the current state of the sidebar back to default
      // values
      this.model.companyName = this.savedFields.companyName
      this.model.policyNumber = this.savedFields.policyNumber
      this.model.companyCode = this.savedFields.companyCode
      this.model.vin = this.savedFields.vin
      this.model.signatureId = this.savedFields.signatureId
      this.isReset = false
      this.isSignatureProcessing = false

      this.$root.$emit('bv::toggle::collapse', this.id)
    },

    reset () {
      this.isReset = true
      this.model.companyName = ''
      this.model.policyNumber = ''
      this.model.companyCode = ''
      this.model.vin = ''
      this.model.signatureId = ''
    },

    openCompanyCodeModal () {
      this.$bvModal.show(this.companyCodeModalId)
    },

    handleProcessingSignature (value) {
      if (value) {
        this.isSignatureProcessing = true
        this.model.signatureId = ''
      }
      else {
        this.isSignatureProcessing = false
      }
    },
  },
}
</script>
